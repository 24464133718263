const settings = JSON.parse(document.querySelector('[data-settings]').innerHTML);
const translations = JSON.parse(document.querySelector('[data-translations]').innerHTML);

export const setting = key => {
    const settingsExists = settings.hasOwnProperty(key);
    if (!settingsExists) {
        console.error(`Missing setting for "${key}"!`);
    }
    return settingsExists ? settings[key] : null;
}

export const translate = key => {
    const translationExists = translations.hasOwnProperty(key);
    if (!translationExists) {
        console.error(`Missing translation for "${key}"!`);
    }
    return translationExists ? translations[key] : key;
}
