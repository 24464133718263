import { setting, translate as t } from "./Components/Settings";

// the matomo ID, if set
const trackingID = setting('tracking_id');
const trackingURL = setting('tracking_url');

// setup javascript trcaker & page view tracking
window._paq = window._paq || [];
window._paq.push(["setDoNotTrack", true]);
window._paq.push(["disableCookies"]);
window._paq.push(["alwaysUseSendBeacon"]);
window._paq.push(['trackPageView']);
window._paq.push(['enableLinkTracking']);
(function() {
    var u=`https://${trackingURL}/`;
    window._paq.push(['setTrackerUrl', u+'matomo.php']);
    window._paq.push(['setSiteId', `${trackingID}`]);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
})();
